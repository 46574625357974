import * as React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { getUser } from 'src/redux/slices/user';
import useSettings from "src/hooks/useSettings";
import Page from "src/components/Page";
import {
    Container,
    Typography,
    Button,
    Box,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";

export default function Home() {
    const { themeStretch } = useSettings();
    const { user } = useSelector((state) => state?.user);
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(getUser());
    }, []);

    return (
        <Page title="Agents" height={"100%"}>
            <Container maxWidth={themeStretch ? false : "lg"}>
                <Stack direction="row">
                    <Typography
                        display={"flex"}
                        sx={{ ml: 0, mr: 'auto', my: 3, color: '#0053A7', fontWeight: '600' }}
                    >
                        Welcome, {user?.name}!
                    </Typography>
                </Stack>

                <Paper
                    sx={{
                        width: "100%",
                        overflow: "hidden",
                        borderRadius: "10px",
                        p: 3,
                    }}
                >
                    <Stack sx={{
                        flexDirection: 'row'
                    }}>
                        <Stack sx={{
                            flexDirection: 'row',
                            display: 'flex',
                            alignItems: 'center',
                            width: '50%',
                        }}>
                            <img src="/assets/home/full_1.png" />
                            <Button
                                variant="contained"
                                sx={{
                                    boxShadow: "0px 4px 4px 0px #3EB54B4D",
                                    height: '42px',
                                    whiteSpace: 'nowrap',
                                    ml: '-150px',
                                    mt: '-40px'
                                }}
                            >
                                Create an Agent
                            </Button>
                        </Stack>
                        <Stack sx={{
                            flexDirection: 'row',
                            display: 'flex',
                            alignItems: 'center',
                            width: '50%',
                        }}>
                            <img src="/assets/home/full_2.png" />
                            <Button
                                variant="contained"
                                sx={{
                                    boxShadow: "0px 4px 4px 0px #3EB54B4D",
                                    height: '42px',
                                    whiteSpace: 'nowrap',
                                    ml: '-150px',
                                    mt: '-40px'
                                }}
                            >
                                Go to my Agents
                            </Button>
                        </Stack>
                    </Stack>
                    <Box sx={{ position: 'relative', mt: '140px' }}>
                        <Box
                            component="img"
                            src="/assets/home/full_3.png"
                            alt="Help Image"
                            sx={{
                                position: 'absolute',
                                top: '-60px',
                                left: '20%',
                                transform: 'translateX(-50%)',
                            }}
                        />
                        <Stack
                            sx={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                background: '#EDEDED',
                                borderRadius: '13px',
                                padding: '60px 10px 60px 40%',
                                zIndex: 1,
                                display: 'flex',
                            }}
                        >
                            <Typography>
                                <span style={{ fontWeight: '600', fontSize: '20px', marginRight: '30px' }}>
                                    Need help?
                                </span>
                                <span>
                                    <i>
                                        check <b>How to</b> or you can ask our <b>bot</b> for help
                                    </i>
                                </span>
                            </Typography>
                        </Stack>
                    </Box>
                </Paper>
            </Container>
        </Page>
    );
}
